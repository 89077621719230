import type { AppProps, NextWebVitalsMetric } from 'next/app'

import { Vitals } from '../types'

import '../styles/app.scss'

const App = ({ Component, pageProps }: AppProps) => <Component {...pageProps} />

const vitals = {
  FCP: {
    optimalRange: 1800,
    decentRange: 3000,
  },
  LCP: {
    optimalRange: 2500,
    decentRange: 4000,
  },
  CLS: {
    optimalRange: 100,
    decentRange: 250,
  },
  FID: {
    optimalRange: 100,
    decentRange: 300,
  },
  TTFB: {
    optimalRange: 200,
    decentRange: 600,
  },
  INP: {
    optimalRange: 50,
    decentRange: 100,
  }
} as Vitals

const vitalFormula = (optimalRange: number, decentRange: number, actualRange: number) => {
  const percentage = (1 - (actualRange - optimalRange) / (decentRange - optimalRange)) * 100
  return Math.max(0, Math.min(100, percentage))
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (!window.location.search.includes('vitals')) return
  const { name, value } = metric

  if (name in vitals) {
    const { optimalRange, decentRange } = vitals[name]
    const score = vitalFormula(optimalRange, decentRange, value)
    let color = '🔴'
    if (score >= 75) {
      color = '🟢'
    } else if (score >= 50) {
      color = '🟠'
    }
    // eslint-disable-next-line no-console
    console.log(`${name}: ${score} ${color}`)
  }
}

export default App